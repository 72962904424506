import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { combineLatest } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { pipe } from 'shared/utils/rxjs.utils';

import { ApiGlobalOrgService } from '../../shared/api/global-org/api-global-org.service';
import { ApiGlobalUserService } from '../../shared/api/global-user/api-global-user.service';
import { sharedState } from '../../shared/store/shared.state';

@Injectable({ providedIn: 'root' })
export class MainResolver implements Resolve<any> {

  constructor(
    readonly apiGlobalOrg: ApiGlobalOrgService,
    readonly apiGlobalUser: ApiGlobalUserService,
  ) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return combineLatest([
      pipe(
        concatMap(() => this.apiGlobalOrg.getAllOrganizations()),
        tap(response => sharedState.dispatch.organizations(__filename, response)),
      ),
      pipe(
        concatMap(() => this.apiGlobalUser.getAllUserPersonProfiles()),
        tap(response => sharedState.dispatch.profiles(__filename, response))
      ),
      pipe(
        concatMap(() => this.apiGlobalUser.getAllUserAccounts()),
        tap(response => sharedState.dispatch.accounts(__filename, response)),
      )
    ]);
  }
}
