import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { configure, defaultDispatchTagPreprocessor } from 'g2i-ngrx-utils';
import { ResolverMonitorService } from 'shared/services/resolver-monitor';
import { AppState } from '../shared/store/interfaces';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styles: [` :host { flex: 1; display: flex; } `],
  standalone: true,
  imports: [ RouterModule ],
})
export class AppComponent {
  constructor(
    readonly store: Store<AppState>,
    readonly resolverMonitor: ResolverMonitorService, // please do not remove this constructor arg.
  ) {
    configure({
      dispatchFunction: (arg: any) => this.store.dispatch(arg),
      dispatchTagPosition: 'append',
      dispatchTagPreprocessor: defaultDispatchTagPreprocessor,
    });
  }
}
