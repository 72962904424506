import { GlobalAdminAppGuard } from 'g2i-ng-auth';
import { AppRoute, shellRoutes } from 'shared/constants/route.consts';
import { ShellAppGuard } from 'shared/services/shell-app.guard';

import { MainResolver } from './main/main.resolver';


export const routes: AppRoute[] = [
  ...shellRoutes,
  {
    path: 'app',
    loadChildren: () => import('./main/main.routes').then(m => m.routes),
    canActivate: [ShellAppGuard, GlobalAdminAppGuard],
    data: {
    },
    resolve: { data: MainResolver },
  },
  {
    path: '**',
    redirectTo: 'landing',
  },
];
