import { manageStateSlice } from 'g2i-ngrx-utils';
import { ServiceEventsAndUsers } from 'shared/api/logging/api-logging.responses';
import {
  Application,
  OrganizationUserAccount,
} from 'shared/api/organization/api-organization.response';
import { UserAccountId } from 'shared/constants/id.constants';

export const organizationsState = manageStateSlice({
  orgList: {
    scrollPos: 0,
    filters: {
      archived: false,
      search: '',
    }
  },
  adminList: {
    scrollPos: 0,
    filters: {
      search: '',
    }
  },
  orgUserList: {
    scrollPos: 0,
    filters: {
      search: '',
    }
  },
  activityList: {
    filters: {
      startDate: null as string | null,
      endDate: null as string | null,
    },
    listItems: {
      serviceEvents: [],
      userPersonProfiles: []
    } as ServiceEventsAndUsers,
  },
  orgUserAccounts: new Array<OrganizationUserAccount>(),
  applications: new Array<Application>(),
})({
  activityList: (state, payload: ServiceEventsAndUsers) => {
    state.activityList.listItems = payload;
  },
  activityListAppend: (state, payload: ServiceEventsAndUsers) => {
    state.activityList.listItems.serviceEvents.push(...payload.serviceEvents);
    state.activityList.listItems.userPersonProfiles.push(...payload.userPersonProfiles);
  },
  activityListFilters: (state, payload: { startDate: string | null; endDate: string | null }) => {
    state.activityList.filters = payload;
  },
  orgListScroll: (state, payload: number) => {
    state.orgList.scrollPos = payload;
  },
  adminListScroll: (state, payload: number) => {
    state.orgList.scrollPos = payload;
  },
  adminListFilters: (state, payload: { search: string }) => {
    state.adminList.filters = payload;
  },
  userListFilters: (state, payload: { search: string }) => {
    state.orgUserList.filters = payload;
  },
  orgListFilters: (state, payload: { archived: boolean; search: string }) => {
    state.orgList.filters = payload;
  },
  orgUserAccounts: (state, payload: OrganizationUserAccount[]) => {
    state.orgUserAccounts = payload;
  },
  orgUserAccountUpdate: (state, payload: OrganizationUserAccount) => {
    state.orgUserAccounts.replace(payload, e => e.id);
  },
  orgUserAccountAdd: (state, payload: OrganizationUserAccount) => {
    state.orgUserAccounts.push(payload);
  },
  orgUserAccountRemove: (state, payload: UserAccountId) => {
    state.orgUserAccounts.remove(e => e.userAccountId === payload);
  },
  applications: (state, payload: Application[]) => {
    state.applications = payload;
  },
});
