import { manageStateSlice } from 'g2i-ngrx-utils';
import { Organization, UserAccount, UserPersonProfile } from 'shared/api/organization/api-organization.response';
import { UserAccountId } from 'shared/constants/id.constants';

export const sharedState = manageStateSlice({
  profiles: new Array<UserPersonProfile>(),
  accounts: new Array<UserAccount>(),
  organizations: new Array<Organization>(),
})({
  profiles: (state, payload: UserPersonProfile[]) => {
    state.profiles = payload;
  },
  profileAdd: (state, payload: UserPersonProfile) => {
    state.profiles.push(payload);
  },
  profileUpdate: (state, payload: UserPersonProfile) => {
    state.profiles.replace(payload, e => e.id);
  },
  organizations: (state, payload: Organization[]) => {
    state.organizations = payload;
  },
  orgCreate: (state, payload: Organization) => {
    state.organizations.push(payload);
  },
  orgUpdate: (state, payload: Organization) => {
    state.organizations.replace(payload, e => e.id);
  },
  accounts: (state, payload: UserAccount[]) => {
    state.accounts = payload;
  },
  accountAdd: (state, payload: UserAccount) => {
    state.accounts.push(payload);
  },
  accountUpdate: (state, payload: UserAccount) => {
    state.accounts.replace(payload, e => e.id);
  },
  accountDelete: (state, payload: UserAccountId) => {
    state.accounts.remove(e => e.id === payload);
  },
});
