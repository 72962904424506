import { manageStateSlice } from 'g2i-ngrx-utils';
import {
  ProfessionalProfileSave,
} from 'projects/app-management/src/shared/api/global-professional/api-global-professional.responses';
import { ServiceEventsAndUsers } from 'shared/api/logging/api-logging.responses';
import {
  Application,
  ApplicationRole,
  Organization,
  OrganizationUserAccount,
  Role,
  UserPersonProfile,
} from 'shared/api/organization/api-organization.response';
import {
  ProfessionalProfile,
  ProfessionalProfileGrant,
  ProfessionalType,
} from 'shared/api/professional/api-professional.responses';


export const usersState = manageStateSlice({
  userList: {
    scrollPos: 0,
    filters: {
      search: '',
      archived: false,
    }
  },
  adminList: {
    scrollPos: 0,
    filters: {
      search: '',
    }
  },
  userDetail: {
    profile: {} as UserPersonProfile,
    accounts: new Array<OrganizationUserAccount>(),
    organization: {} as Organization,
    professionalProfiles: new Array<ProfessionalProfile>(),
    professionalProfileGrants: new Array<ProfessionalProfileGrant>(),
  },
  applications: new Array<Application>(),
  applicationRoles: new Array<ApplicationRole>(),
  roles: new Array<Role>(),
  professionalTypes: new Array<ProfessionalType>(),
  activityList: {
    filters: {
      startDate: '',
      endDate: '',
    },
    listItems: {} as ServiceEventsAndUsers,
  }
})({
  activityList: (state, payload: ServiceEventsAndUsers) => {
    state.activityList.listItems = payload;
  },
  activityListAppend: (state, payload: ServiceEventsAndUsers) => {
    state.activityList.listItems.serviceEvents.push(...payload.serviceEvents);
    state.activityList.listItems.userPersonProfiles.push(...payload.userPersonProfiles);
  },
  activityListFilters: (state, payload: { startDate: string | null; endDate: string | null }) => {
    Object.assign(state.activityList.filters, payload);
  },
  userListFilters: (state, payload: { search: string; archived: boolean }) => {
    state.userList.filters = payload;
  },
  adminListFilters: (state, payload: { search: string }) => {
    state.adminList.filters = payload;
  },
  detailProfile: (state, payload: UserPersonProfile) => {
    state.userDetail.profile = payload;
  },
  detailAccounts: (state, payload: OrganizationUserAccount[]) => {
    state.userDetail.accounts = payload;
  },
  detailAccount: (state, payload: OrganizationUserAccount) => {
    state.userDetail.accounts.replace(payload, e => e.id);
  },
  detailOrganization: (state, payload: Organization) => {
    state.userDetail.organization = payload;
  },
  userListScroll: (state, payload: number) => {
    state.userList.scrollPos = payload;
  },
  adminListScroll: (state, payload: number) => {
    state.adminList.scrollPos = payload;
  },
  applications: (state, payload: Application[]) => {
    state.applications = payload;
  },
  applicationRoles: (state, payload: ApplicationRole[]) => {
    state.applicationRoles = payload;
  },
  roles: (state, payload: Role[]) => {
    state.roles = payload;
  },
  detailProfessionalProfiles: (state, payload: ProfessionalProfile[]) => {
    state.userDetail.professionalProfiles = payload;
  },
  detailProfessionalProfileGrants: (state, payload: ProfessionalProfileGrant[]) => {
    state.userDetail.professionalProfileGrants.merge(payload, e => e.id);
  },
  professionalTypes: (state, payload: ProfessionalType[]) => {
    state.professionalTypes = payload;
  },
  detailProfessionalProfileAdd: (state, payload: ProfessionalProfile) => {
    state.userDetail.professionalProfiles.push(payload);
  },
  detailProfessionalProfileUpdate: (state, payload: ProfessionalProfileSave) => {
    state.userDetail.professionalProfiles.replaceElseInsert(payload.professionalProfile, e => e.id);
    state.userDetail.professionalProfileGrants.merge(payload.professionalProfileGrants, e => e.id);
  },
});
