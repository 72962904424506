import { manageStateSlice } from 'g2i-ngrx-utils';
import { Contact, Place } from 'shared/api/place/api-place.responses';
import { PlaceId, PlaceTypeId } from 'shared/constants/id.constants';

export const placesState = manageStateSlice({
  listing: {
    scrollPos: 0,
    listItems: new Array<Place>(),
    filters: {
      archived: false,
      searchString: '',
    }
  },
  types: new Array<string>(),
  timezones: new Array<string>(),
  place: {} as Place,
  contacts: new Array<Contact>(),
})({
  listScroll: (state, payload: number) => {
    state.listing.scrollPos = payload;
  },
  placesListFilters: (state, payload: { archived: boolean; searchString: string }) => {
    state.listing.filters = payload;
  },
  placesReplace: (state, payload: Place[]) => {
    state.listing.listItems = payload;
  },
  placesAppend: (state, payload: Place[]) => {
    state.listing.listItems.push(...payload);
  },
  placeRemove: (state, payload: PlaceId) => {
    state.listing.listItems.remove(e => e.id === payload);
  },
  listReset: (state) => {
    state.listing = placesState.initialState.listing;
    state.listing.listItems = [];
  },
  placeTypes: (state, payload: PlaceTypeId[]) => {
    state.types = payload;
  },
  timezones: (state, payload: string[]) => {
    state.timezones = payload;
  },
  place: (state, payload: Place) => {
    state.place = payload;
  },
  contacts: (state, payload: Contact[]) => {
    state.contacts = payload;
  },
  contactAdd: (state, payload: Contact) => {
    state.contacts.push(payload);
  },
  contactUpdate: (state, payload: Contact) => {
    state.contacts.replace(payload, e => e.id);
  },
  contactRemove: (state, payload: number) => {
    state.contacts.remove(e => e.id === payload);
  },
});
