import { Injectable } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';
import { Organization } from 'shared/api/organization/api-organization.response';
import { OrganizationId } from 'shared/constants/id.constants';

import {
  CreateOrganizationRequest,
  SaveOrganizationApplicationsRequest,
  SetOrganizationArchivedRequest,
  UpdateOrganizationRequest,
} from './api-global-org.requests';

@Injectable({ providedIn: 'root' })
export class ApiGlobalOrgService extends ApiBaseService {

  constructor() {
    super(() => `${this.serviceUrl.organization}/api/global/organization/`);
  }

  getOneOrganization = (organizationId: OrganizationId) =>
    this.request<Organization>(organizationId, 'GET');
  getAllOrganizations = () =>
    this.request<Organization[]>(``, 'GET');
  createOrganization = (request: CreateOrganizationRequest) =>
    this.request<Organization>(``, 'POST', request);
  updateOrganization = (request: UpdateOrganizationRequest) =>
    this.request<Organization>(``, 'PUT', request);
  setOrganizationArchived = ({ id, archived }: SetOrganizationArchivedRequest) =>
    this.request<Organization>(`${id}/archive`, 'PATCH', { archived });
  saveOrganizationApps = ({ organizationId, organizationApplicationIds }: SaveOrganizationApplicationsRequest) =>
    this.request<Organization>(`${organizationId}/application`, 'POST', organizationApplicationIds);
}
