import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { combineManagers } from 'g2i-ngrx-utils';
import { shellState } from 'shared/store/shell.state';

import { organizationsState } from '../../app/main/organizations/organizations.state';
import { placesState } from '../../app/main/places/places.state';
import { usersState } from '../../app/main/users/users.state';
import { AppState } from './interfaces';
import { sharedState } from './shared.state';

export const managers = combineManagers({
  shared: sharedState,
  shell: shellState,
  users: usersState,
  places: placesState,
  organizations: organizationsState,
});

export const reducerToken = new InjectionToken<ActionReducerMap<AppState>>('ManagementReducers');

export const getReducers = () => managers.reducers;
